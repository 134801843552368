import { BlockTypes } from '-/leap-models';
import { Callout } from './Callout';
import { Section } from './Section';
import { WaitlistCapture } from './WaitlistCapture';

import type { Block } from '..';

export interface InternalBlockProps extends Block {
  type: 'link' | 'referral_link' | 'callout' | 'header' | 'emailCapture';
  to?: string;
  isPreview?: boolean;
  isExternal: boolean;
  className?: string;
}

export const BLOCK_TYPE_TO_CALLOUT_TYPE: { [key: string]: string } = {
  [BlockTypes.LINK]: 'link',
  [BlockTypes.EMAIL_CAPTURE]: 'emailCapture',
  [BlockTypes.SEPARATOR]: 'separator',
  [BlockTypes.REFERRAL_LINK]: 'referral_link',
};

export const StorefrontBlock = (props: InternalBlockProps) => {
  const type =
    BLOCK_TYPE_TO_CALLOUT_TYPE[props.blockType?.type || ''] || 'callout';
  const blockResolver: { [key: string]: any } = {
    link: Callout,
    referral_link: Callout,
    callout: Callout,
    header: Section,
    separator: Section,
    emailCapture: WaitlistCapture,
  };
  const Component = blockResolver[type];

  if (!Component) {
    console.error(`Could not resolver component for block ${props.type}`);
    return null;
  }

  return <Component {...props} type={type} />;
};

export default StorefrontBlock;
