import { twMerge } from 'tailwind-merge';

import { PlatformIcon } from '-/aslan';
import { SocialMediaEnum } from '-/utils';

import type { SocialMediaLink, SocialMediaLinkList } from '..';

const SocialMediaLinks = ({
  socialMedia,
  className,
}: {
  socialMedia?: SocialMediaLinkList;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        'flex flex-wrap gap-x-5 gap-y-4 px-3 align-center items-center text-center justify-center',
        className
      )}
    >
      {socialMedia?.map((platform: SocialMediaLink, index: number) => {
        const name =
          SocialMediaEnum[platform.type as keyof typeof SocialMediaEnum];
        return (
          <a
            key={index}
            href={platform.url}
            target="_blank"
            rel="noopener noreferrer"
            className="social-media-button w-9 h-9 inline-flex items-center justify-center"
            aria-label={`${name} Link`}
          >
            <PlatformIcon
              className="h-4 w-4 fill-icon-text"
              iconName={`${name}SVG`}
            />
          </a>
        );
      })}
    </div>
  );
};

export default SocialMediaLinks;
