import { Text } from '-/aslan';
import { H2 } from './ui/Typography';

interface SectionProps {
  title?: string;
  description?: string;
}

const Section = ({ title, description }: SectionProps) => {
  return (
    <div className="text-center space-y-2">
      {title && <H2 className="font-bold text-lg break-words">{title}</H2>}
      {description && (
        <Text size="base" className="break-words">
          {description}
        </Text>
      )}
    </div>
  );
};

export { Section };
